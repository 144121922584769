<template>
    <form autocomplete="off" class="">
        <div>
            <div class="bd-callout mb-3 mt-0 bd-callout-info">
                <h6>Delivered quantity: {{ editItem.quantity }} </h6>
                <span>{{ editItem.quantity }} {{ item.medicine.minimum_unit }}(s) of {{ item.medicine.name }} - {{ item.medicine.description }}</span>
            </div>
        </div>
        <div class="row gy-3">
            <div class="col-12">
                <label class="form-label" for="inputQuantity">Enter Received quantity </label>
                <input id="inputQuantity" type="number" class="form-control" v-model="data.quantity"
                :class="{ 'is-invalid': v$.data.quantity.$error }" placeholder="Enter quantity" name="quantity" />
                <div v-for="(item, index) in v$.data.quantity.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Write a remark</label>
                <textarea v-model="data.confirmed_remark" class="form-control"
                :class="{ 'is-invalid': v$.data.confirmed_remark.$error }"
                rows="3" placeholder="Write a remark" name="confirmed_remark"></textarea>
                <div v-for="(item, index) in v$.data.confirmed_remark.$errors"
                    :key="index" class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div>
                <label class="form-label">Attach Picture</label>
                <div class="d-grid">
                    <button type="button"  @click="popupTakePicture =  true" class="btn mb-2 btn-outline-dark">
                         <span class="mdi mdi-camera"></span> Take Picture </button>

                    <input type="file" ref="fileInput" accept="image/*" style="display:none;" @change="handleFileChange">
                    <button type="button" class="btn btn-outline-primary" @click="triggerFileInput">Upload Image</button>
                </div>
                <div v-if="attachedImage" class="py-3 text-center">
                    <img :src="attachedImage" class="w-50" />
                </div>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="confirmUpdate()"
                     type="button">Submit</b-button>
                </div>
            </div>
        </div>

        <b-modal hide-footer hide-header  v-model="popupTakePicture" centered
            no-close-on-esc no-close-on-backdrop class="web-cam-moal-backdrop" 
            @hide="popupTakePicture = false" body-class="bg-light">
            <vue-web-cam v-if="popupTakePicture"
             @acceptImage="acceptCameraImage($event)"
             @closeMe="popupTakePicture = false" />
        </b-modal>
    </form>
</template>

<script>
import { required, minValue, maxValue, requiredIf, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import VueWebCam from '@/components/VueWebCam.vue';

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        VueWebCam
    },
    props:{
        item:{
            type: Object,
            required: true
        },
        editItem:{
            type: Object,
            required: true
        },
    },
    data(){
        return{
            data: {
                quantity: "",
                confirmed_remark:"",
            },
            attachedImage: null,
            attachedPhoto: null,
            popupTakePicture: false
        }
    },
    validations() {
        return {        
            data:{
                quantity: {
                    required: helpers.withMessage("Quantity is required", required),
                    maxValue: maxValue(this.maxQuantity),
                    minValue: minValue(1)
                },            
                confirmed_remark: { 
                    required: helpers.withMessage("Remark is required", required),
                },            
            }
        }
    },
    computed:{
        maxQuantity(){
            return this.editItem.quantity
        }
    },
    methods:{
        confirmUpdate(){
            this.v$.$validate().then(result =>{
                if (!result) {this.alertError('Form is not valid'); return;}
                if(!this.attachedPhoto){ this.alertError("Please attach picture"); return;}
                this.confirmAction({
                    text: `You are about to confirm the receipt of: 
                    <h6>${this.$filters.number_format(this.data.quantity)} ${this.item.medicine.minimum_unit}(s) of ${this.item.medicine.name}</h6>
                    Please ensure your details are accurate.`,
                }).then((result) => {
                    if (result.value) { this.update(); }
                });
            })
        },
        update(){
            let formData = new FormData();
            formData.append('image_file', this.attachedPhoto)
            formData.append('data', JSON.stringify(this.data))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/item-deliveries/${this.editItem.id}/confirm`, formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$emit('updatedItem', response.data.data)
                    this.resetForm()
                }
            })
        },
        acceptCameraImage(data){
            this.attachedImage = data.url;
            this.attachedPhoto = data.file
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        handleFileChange(event) {
            const file = event.target.files[0];
            if(file.size > 2097152){
                this.alertError('File size is more than 2MB'); return;
            }
            this.$filters.fileReader(file)
                .then(result => {
                    this.attachedImage = result.dataURL;
                    this.attachedPhoto = file
                })
                .catch(error => {
                    this.alertError('Error reading file '+error);
                });
        },
        resetForm(){
            this.v$.$reset()
            this.popupTakePicture = false
            this.$emit("closeMe")
        }
    },
    mounted(){

    }
}
</script>

<style scoped>

</style>